@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
html {
  background-color: #000;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  /* overflow: hidden; */
  /* -webkit-overflow-scrolling: touch; */
}

.bg-img {
  /* Background image */
  background-image: url(/static/media/bg.36bbd769.jpg);
  /* Full-screen */
  min-height: 100vh;
  height: 100%;
  /* Center the background image */
  background-position: center;
  /* Scale and zoom in the image */
  background-size: cover;
  /* Add position: relative to enable absolutely positioned elements inside the image (place text) */
  position: relative;
  /* Add a white text color to all elements inside the .bgimg container */
  color: white;
  /* Add a font */
  /* Set the font-size to 25 pixels */
  font-size: 25px;
  width: 100%;
}

img {
  max-width: 60%;
  max-height: 60vh;

  /* max-width: 60%;
  height: auto; */
}

/* Position text in the top-left corner */
/* .topleft {
  position: absolute;
  top: 0;
  left: 16px;
} */

/* Position text in the bottom-left corner */
/* .bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
} */

/* Position text in the middle */
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Style the <hr> element */
hr {
  margin: auto;
  width: 40%;
}

.bg-layout {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: block;
  min-height: 100vh;
  height: 100%;
}

.h1 {
  color: #ddd;
  font-size: 48px;
  margin: 20px 0;
  font-weight: bold;
}

.brand-logo {
  width: 160px;
  border-radius: 10px;
}

.social_icon_wrapper {
  margin-top: 1%;
}

.social_icon {
  padding-right: 15px;
  font-size: 18px;
}

.social_icon:hover {
  cursor: pointer;
}

.fa-facebook-f:hover {
  color: #3b5998;
}

.fa-twitter:hover {
  color: #1da1f2;
}

.fa-linkedin-in:hover {
  color: #0077b5;
}

.fa-instagram:hover {
  color: #e4405f;
}

@media screen and (min-width: 400px) {
  .brand-logo {
    width: 200px;
  }
}
.services {
  margin-top: 20px;
}
.services span {
  text-transform: uppercase;
}
.c-wrap {
  display: flex;
  justify-content: center;
  position: absolute;
  background: #dadada;
  bottom: 0;
  width: 100%;
  height: auto;
  top: 220px;
}
.content {
  width: 100%;
}
.content input,
.emails {
  height: 36px;
  border-radius: 40px;
  outline: none;
  border: 0;
  background: #efefef;
  text-align: center;
  width: 100%;
  max-width: 400px;
  display: block;
  margin: 0 auto;
  font-size: 14px;
}
.button,
.buttons {
  cursor: pointer;
  background-color: #333; /* Green */
  border: none;
  color: white;
  padding: 5px 0;
  text-align: center;
  text-decoration: none;
  /* display: inline; */
  font-size: 16px;
  /* margin: 10px 0; */
  border-radius: 40px;
  outline: none;
  border: 0;
  width: 170px;
  border: 1px solid transparent;
}
.button:active,
.button:focus {
  border: 1px solid #fff;
}
.button-social {
  background: transparent;
  border: none;
  padding: 0 8px;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  position: relative;
  top: 0;
}
.button-social:hover {
  position: relative;
  top: -2px;
  opacity: 0.8;
}
.content p {
  font-size: 14px;
}
.button:hover {
  opacity: 0.9;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 25px 100px 0;
}

header h2 {
  margin-bottom: 15px;
}

.flex-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  /* justify-content: center; */
  /* max-width: 60%;
  height: auto; */
}

/* .flex-form > * {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
} */

.flex-form > button:hover {
  background: #d73851;
  border: 1px solid #d73851;
}

.flex-form input[type="checkbox"] {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #ddd;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
}

.flex-form input[type="checkbox"]:checked {
  background-color: #d73851;
}

.flex-form .beta {
  display: flex;
  padding-top: 10px;
}

.made-by {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 10px;
  color: rgba(255, 255, 255, 0.3) !important;
  font-size: 14px;
}

.sub-titles {
  color: rgb(255, 255, 255, 1);
  text-align: center;
  text-shadow: 0 0 40px #000, 0 0 30px #000, 0 0 20px #000;
}

.tag-line {
  font-size: 1em;
  font-weight: bold;
  padding-bottom: 20px;
}

/* “If [device width] is less than or equal to 800px, then do {…}” */
@media all and (max-width: 800px) {
  header {
    flex-direction: column;
    padding: 10px !important;
  }
}

/* Remove header for desktops that minimize window vertically */
@media only screen and (max-height: 650px) and (min-width: 1224px) {
  header {
    display: none !important;
  }
}

